<template>
  <Popup
    title="주소 검색"
    :maxWidth="600"
    closeBtnText="취소"
    @onClickClose="$emit('onClickClose')">
    <div id="wrap" class="wrap_address" ref="addressWrap" :style="{width : '100%',height: (getWindowHeight - 300)+'px'}"></div>
  </Popup>
</template>
<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import { mapGetters } from 'vuex';

export default {
  name: 'AddressPopup',
  components:{
    Popup,
  },
  mounted(){
    this.setDaumAddressSearch();
  },
  data(){
    return{
      info:'',
      extraAddr:'',
      address:'',
    }
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowWidth', 'getWindowHeight' ]),
  },
  methods:{
    setDaumAddressSearch(){
      this.$nextTick(() => {
        const element_wrap = document.getElementById('wrap');
        new window.daum.Postcode({
          oncomplete: (data) => {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

            // 각 주소의 노출 규칙에 따라 주소를 조합한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            var addr = ''; // 주소 변수
            var extraAddr = ''; // 참고항목 변수

            //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
            if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
              addr = data.roadAddress;
            } else { // 사용자가 지번 주소를 선택했을 경우(J)
              addr = data.jibunAddress;
            }

            // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
            if(data.userSelectedType === 'R'){
              // 법정동명이 있을 경우 추가한다. (법정리는 제외)
              // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
              if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                  extraAddr += data.bname;
              }
              // 건물명이 있고, 공동주택일 경우 추가한다.
              if(data.buildingName !== '' && data.apartment === 'Y'){
                  extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
              }
              // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
              if(extraAddr !== ''){
                  extraAddr = ' (' + extraAddr + ')';
              }
              // 조합된 참고항목을 해당 필드에 넣는다.
              //document.getElementById("sample6_extraAddress").value = extraAddr;
            
            } else {
              //document.getElementById("sample6_extraAddress").value = '';
            }
            // console.log(data.zonecode, addr);

            // this.$emit("update:zipcode", data.zonecode);
            // this.$emit("update:address", addr);
            this.address = addr;
            this.extraAddr = extraAddr;
            this.info = data;
            this.postAddressData();
            
            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            // document.getElementById('addressPostcode').value = data.zonecode;
            // document.getElementById("address1").value = addr;
            // 커서를 상세주소 필드로 이동한다.
            //document.getElementById("address2").focus();
          },
          width:'100%',
          height:'100%',
          theme: {
            bgColor: "#FFFFFF", //바탕 배경색
            searchBgColor: "#F3F1F1", //검색창 배경색
            contentBgColor: "#F3F1F1", //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
            pageBgColor: "#FFFFFF", //페이지 배경색
            textColor: "#111111", //기본 글자색
            queryTextColor: "#111111", //검색창 글자색
            postcodeTextColor: "#111111", //우편번호 글자색
            emphTextColor: "#BEA9ED", //강조 글자색
            outlineColor: "#FFFFFF" //테두리
          }
        }).embed(element_wrap, {
          autoClose: false //기본값 true
        });
      });
    },
    postAddressData(){
      this.$emit('onClickComplete',this.info)
    },
  }
}
</script>
<style scoped>
.wrap_address{position:relative;width:100%;height:100%}
</style>