<template>
  <PageWithLayout>
    <search
      :searchDataList.sync="viewModel.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onSearch="viewModel.onSearchList()"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      :emptyDesc="viewModel.CARD_RETURN.searched && !viewModel.CARD_RETURN.hasData ? '검색결과가 없습니다' : '반송 기간을 검색해주세요'"
      @onSearch="viewModel.onSearchList()">
    </Board>
    <template v-slot:popup>
      <CardReDeliveryPopup
        v-if="viewModel.isCardReDeliveryPopup"
        :detailData="viewModel.detailData"
        @onClickClose="viewModel.onClickCloseCardReDeliveryPopup()"
        @onClickComplete="viewModel.onClickCompleteCardReDeliveryPopup()"/>
      <CardReDeliveryCheckPopup
        v-if="viewModel.isCardReDeliveryCheckPopup"
        :detailData="viewModel.detailData"
        @onClickClose="viewModel.onClickCloseCardReDeliveryCheckPopup()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// popup
import CardReDeliveryPopup from '@/views/cs/card/return/view/popup/CardReDeliveryPopup'
import CardReDeliveryCheckPopup from '@/views/cs/card/return/view/popup/CardReDeliveryCheckPopup'
// viewModel
import CardReturnListViewModel from '@/views/cs/card/return/viewModel/CardReturnListViewModel'

export default {
  name: 'CardReturnList',
  components:{
    PageWithLayout,
    Search,
    Board,

    CardReDeliveryPopup,
    CardReDeliveryCheckPopup
  },
  data(){
    return{
      viewModel: new CardReturnListViewModel(),
    }
  },
}
</script>
<style scoped>
.board_comm::v-deep .tbl_comm table{min-width:100%;width:auto}
.board_comm::v-deep .tbl_comm table .txt_tbl{display:block;max-width:200px}
.board_comm::v-deep .tbl_comm table .td_mark .txt_tbl{display:inline-block}
</style>