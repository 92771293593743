<template>
  <Popup
    :title="`${detailData.ownerName} 카드 재배송 확인`"
    :maxWidth="800"
    closeBtnText="닫기"
    @onClickClose="$emit('onClickClose')">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col/>
        <col style="width:160px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>재배송 / 폐기</th>
          <td colspan="3">
            <StatusSelect
              :dataList="'cs_re_delivery_type' | getSelectDataList"
              :status="viewModel.deliveryCheckData.re_delivery_type"/>
          </td>
        </tr>
        <tr>
          <th>성공유무</th>
          <td>
            <span :class="`txt_tbl fw_bold tc_${$options.filters.convertIdToColor(viewModel.deliveryCheckData.response_type, 'cs_response_type')}`">{{ viewModel.deliveryCheckData.response_type | convertIdToText('cs_response_type') }}
            </span>
          </td>
          <th>상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="viewModel.deliveryCheckData.status | convertIdToText('cs_response_status')"
                :badgeStyle="viewModel.deliveryCheckData.status | convertIdToColor('cs_response_status')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
        <tr>
          <th>신청서 ID</th>
          <td colspan="3">{{ viewModel.deliveryCheckData.application_id }}</td>
        </tr>
        <tr>
          <th>신청서 Pay ID</th>
          <td>{{ viewModel.deliveryCheckData.applicant_pay_id }}</td>
          <th>자녀 Pay ID</th>
          <td>{{ viewModel.deliveryCheckData.owner_pay_id }}</td>
        </tr>
        <tr>
          <th>카드번호</th>
          <td colspan="3">{{ viewModel.deliveryCheckData.card_number }}</td>
        </tr>
        <tr>
          <th>생성일시</th>
          <td colspan="3">{{ viewModel.deliveryCheckData.created_at | convertDateFormat }}</td>
        </tr>
        <tr>
          <th>주소</th>
          <td colspan="3">{{ viewModel.deliveryCheckData.address }}</td>
        </tr>
        <tr>
          <th>설명</th>
          <td colspan="3">{{ viewModel.deliveryCheckData.description }}</td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import StatusSelect from '@lemontree-ai/lemontree-admin-common-front/components/common/statusSelect/StatusSelect';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
// viewModel
import CardReturnDetailViewModel from '@/views/cs/card/return/viewModel/CardReturnDetailViewModel'

export default {
  name:'CardReDeliveryCheckPopup',
  props:{
    detailData: Object
  },
  components:{
    Popup,
    TableView,
    StatusSelect,
    Badge,
  },
  beforeMount(){
    this.viewModel.initCheck(this.detailData)
  },
  data(){
    return{
      viewModel: new CardReturnDetailViewModel(),
    }
  },
}
</script>