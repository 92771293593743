import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import { GET_REQUEST, DELETE_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';

export default class CardReturnListViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'기간조회',
        type: 'DateRangeQuick',
        grid: 100,
        // maxWidth: '300px',
        isFull:true,
        value1:'',
        value2:'',
        quickTypeData:[
          // {
          //   quickType : 'TODAY',
          //   customText: '오늘'
          // },
          {
            quickType : 'ONE_WEEK',
            customText: '최근 7일'
          },
          {
            quickType : 'ONE_MONTH',
            customText: '최근 30일'
          },
          // {
          //   quickType : 'LAST_MONTH',
          //   customText: '지난달'
          // },
        ]
      },
    ];
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.boardData = {
      title:'카드 반송 내역',
      drawDataList: [
        {
          title:'페이 ID',
          width:'80px',
          value:'ownerPayId',
          isAlignLeft: true,
        },
        {
          title:'부모이름',
          width:'68px',
          value:'applicantName',
          isAlignLeft: true,
        },
        {
          title:'부모 휴대전화',
          width:'130px',
          value:'applicantPhoneNumber',
          filter: { name:'convertPhoneNember' },
          isAlignLeft: true,
        },
        {
          title:'자녀이름',
          width:'68px',
          value:'ownerName',
          isAlignLeft: true,
        },
        {
          title:'카드번호',
          width:'150px',
          value:'cardNumber',
          isAlignLeft: true,
        },
        {
          title:'카드신청서상태',
          width:'80px',
          value:'cardApplicationStatus',
          filter: {
            name:'convertIdToText',
            value:'card_apply_status'
          },
        },
        {
          title:'상태',
          width:'100px',
          value:'status',
          isAlignLeft: true,
        },
        {
          title:'반송일',
          width:'100px',
          value:'sendBackDate',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD' },
          isAlignLeft: true,
        },
        {
          title:'반송사유',
          width:'',
          value:'sendBackDescription',
          isAlignLeft: true,
        },
        {
          title:'재배송',
          width:'88px',
          value:'',
          button: { text:'재배송', size:'small', style:'secondary_border', onClickEvent: (data) => this.onClickRedelivery(data) },
        },
        {
          title:'재배송확인',
          width:'102px',
          value:'',
          button: { text:'재배송확인', size:'small', style:'secondary_border', onClickEvent: (data) => this.onClickRedeliveryCheck(data) },
        },
        {
          title:'폐기',
          width:'80px',
          value:'',
          button: { text:'폐기', size:'small', style:'fourthly_border', onClickEvent: (data) => this.onClickCardDiscard(data) },
        },
      ],
      option:{
        isTotal: true,
        isSize: false,
        rowIdValue: 'cardApplicationId'
      },
    }

    this.isCardReDeliveryPopup = false;
    this.isCardReDeliveryCheckPopup = false;
    this.detailData = {};

    this.CARD_RETURN ={
      searched: false,
      hasData: false,
      responseDate:'',
    }
    this.cardDeliveryInfoChange = {
      phone:'',
      address:{
        addressType: "지번 주소:NUMBER_ADDRESS / 도로명 주소:ROAD_ADDRESS",
        address: "string",
        detailAddress: "string",
        zipCode: "string",
        numberAddress: "string",
        roadAddress: "string",
        buildingName: "string",
        isApartment: true,
        sido: "string",
        sigugun: "string",
        dong: "string",
        addressCategory: "집:HOME / 기타:ETC"
      },
    }
  }
  onSearchList(){
    if(!this.searchDataList[0].value1 || !this.searchDataList[0].value2){
      store.dispatch('commonToast/fetchToastStart', '기간을 조회해주세요');
      return;
    }
    this.getCardReturnData();
  }
  onClickRedelivery(data){
    this.isCardReDeliveryPopup = true;
    this.detailData = data;
  }
  onClickCloseCardReDeliveryPopup(){
    this.isCardReDeliveryPopup = false;
    this.detailData = {};
  }
  onClickCompleteCardReDeliveryPopup(){
    this.onClickCardRedelivery();
  }
  onClickRedeliveryCheck(data){
    this.isCardReDeliveryCheckPopup = true;
    this.detailData = data;
  }
  onClickCloseCardReDeliveryCheckPopup(){
    this.isCardReDeliveryCheckPopup = false;
    this.detailData = {};
  }
  // 카드 재배송
  onClickCardRedelivery(){
    const requestDelete = () => {
      this.getCardRedelivery();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `카드를 정말 재배송 하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '카드 재배송',
      confirmCallBack: requestDelete,
    });
  }
  // 카드 폐기
  onClickCardDiscard(data){
    const requestDelete = () => {
      this.getCardDiscard(data);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `카드를 정말 폐기하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '카드 폐기하기',
      confirmCallBack: requestDelete,
    });
  }
  // [API] 카드 반송 조회
  getCardReturnData(){
    this.CARD_RETURN.searched = true;
    const path = `${apiPath.CS_CARD_RETURN.format(this.searchDataList[0].value1,this.searchDataList[0].value2)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData;
      this.CARD_RETURN.hasData = resultData.length > 0;

      this.paginationData.totalCount = resultData.length;
      // this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  
  // [API] 카드 재배송
  getCardRedelivery(){
    const path = `${apiPath.CS_CARD_REDELIVERY.format(this.detailData.cardNumber)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '카드가 재배송 처리되었습니다.');
      this.onClickCloseCardReDeliveryPopup();
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 카드 폐기
  getCardDiscard(data){
    const path = `${apiPath.CS_CARD_DISCARD.format(data.cardNumber)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '카드가 폐기되었습니다.');
      this.onSearchList();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}