<template>
  <Popup
    :title="`${detailData.ownerName} 카드 재배송`"
    :maxWidth="800"
    closeBtnText="닫기"
    compeleteBtnText="재배송"
    :isDisabeldCompeleteBtn="isChange"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete')">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col/>
        <col style="width:90px"/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">배송 정보</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>전화번호</th>
          <td v-if="viewModel.cardDeliveryInfoChange.PHONE.isChange">
            <div class="group_form">
              <Input
                placeholder="재배송시 변경할 전화번호를 입력해주세요"
                :value.sync="viewModel.model.PHONE"
                valueType="tel"
                :isBlockUnValueType="true"/>
            </div>
          </td>
          <td v-else>
            <p>{{ viewModel.model.PHONE | convertPhoneNember }}</p>
          </td>
          <td class="td_center">
            <template v-if="viewModel.cardDeliveryInfoChange.PHONE.isChange">
              <div class="group_from">
                <Button
                  btnSize="small"
                  btnStyle="primary"
                  text="변경"
                  @onClickBtn="viewModel.onClickCardDeliveryInfoPhoneChange()"/>
              </div>
              <div class="group_from">
                <Button
                  btnSize="small"
                  btnStyle="secondary_border"
                  text="취소"
                  @onClickBtn="viewModel.onClickCardDeliveryInfoPhoneChangeCancle()"/>
              </div>
            </template>
            <Button
              v-else
              btnSize="small"
              btnStyle="secondary"
              text="수정"
              @onClickBtn="viewModel.cardDeliveryInfoChange.PHONE.isChange = true"/>
          </td>
        </tr>
        <tr>
          <th>주소</th>
          <td v-if="viewModel.cardDeliveryInfoChange.ADDRESS.isChange">
            <div class="group_form">
              <Radio
                :dataList="'cs_address_cagetory' | getSelectDataList"
                :value.sync="viewModel.model.ADDRESS.addressCategory"/>
            </div>
            <div class="group_form">
              <Input
                placeholder="재배송시 변경할 주소를 입력해주세요"
                :isDisabled="true"
                :value.sync="viewModel.model.ADDRESS.address"/>
              <Button
                btnSize="small"
                btnStyle="secondary_border"
                text="주소검색"
                @onClickBtn="viewModel.onClickAddressSearchPopup()"/>
            </div>
            <div class="group_form">
              <Input
                placeholder="상세주소"
                :value.sync="viewModel.model.ADDRESS.detailAddress"/>
            </div>
          </td>
          <td v-else>
            <div class="group_form">
              <Radio
                :dataList="'cs_address_cagetory' | getSelectDataList"
                :isDisabled="true"
                :value.sync="viewModel.model.ADDRESS.addressCategory"/>
            </div>
            <p class="txt_tbl">{{ viewModel.model.ADDRESS.address }}<br>{{ viewModel.model.ADDRESS.detailAddress }}</p>
          </td>
          <td class="td_center">
            <template v-if="viewModel.cardDeliveryInfoChange.ADDRESS.isChange">
              <div class="group_from">
                <Button
                  btnSize="small"
                  btnStyle="primary"
                  text="변경"
                  @onClickBtn="viewModel.onClickCardDeliveryInfoAddressChange()"/>
              </div>
              <div class="group_from">
                <Button
                  btnSize="small"
                  btnStyle="secondary_border"
                  text="취소"
                  @onClickBtn="viewModel.onClickCardDeliveryInfoAddressChangeCancle()"/>
              </div>
            </template>
            <Button
              v-else
              btnSize="small"
              btnStyle="secondary"
              text="수정"
              @onClickBtn="viewModel.cardDeliveryInfoChange.ADDRESS.isChange = true"/>
          </td>
        </tr>
      </template>
    </TableView>
    <AddressPopup
      v-if="viewModel.isAddressSearchPopup"
      @onClickClose="viewModel.onClickCloseAddressSearchPopup()"
      @onClickComplete="data => viewModel.onClickCompleteAddressSearchPopup(data)"/>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// popup
import AddressPopup from '@/views/cs/card/return/view/popup/AddressPopup';
// viewModel
import CardReturnDetailViewModel from '@/views/cs/card/return/viewModel/CardReturnDetailViewModel'

export default {
  name:'CardReDeliveryPopup',
  props:{
    detailData: Object
  },
  components:{
    Popup,
    TableView,
    Radio,
    Input,
    Button,
    AddressPopup,
  },
  beforeMount(){
    this.viewModel.init(this.detailData)
  },
  computed:{
    isChange(){
      return this.viewModel.cardDeliveryInfoChange.PHONE.isChange || this.viewModel.cardDeliveryInfoChange.ADDRESS.isChange
    }
  },
  data(){
    return{
      viewModel: new CardReturnDetailViewModel(),
    }
  },
}
</script>