import CardReturnModel from '@/views/cs/card/return/model/CardReturnModel'

import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import { POST_REQUEST, GET_REQUEST } from '@/services/http';

export default class CardReturnDetailViewModel {
  constructor() {
    this.detailData = {};
    this.deliveryCheckData = {};
    this.model = new CardReturnModel();
    this.cardDeliveryInfoChange = {
      PHONE:{
        isChange : false,
      },
      ADDRESS:{
        isChange : false,
      }
    }
    this.isAddressSearchPopup = false;
  }
  init(detailData){
    this.detailData = detailData;
    this.model.setPhoneData(detailData);
    this.model.setAddressData(detailData.address);
  }
  initCheck(detailData){
    this.detailData = detailData;
    this.getCardRedeliveryCheck();
  }

  // 배송정보 전화번호 수정
  onClickCardDeliveryInfoPhoneChange(){
    this.cardDeliveryInfoChange.PHONE.isChange = false;
    this.postCardDeliveryInfoPhoneChange();
  }
  // 배송정보 전화번호 수정 취소
  onClickCardDeliveryInfoPhoneChangeCancle(){
    this.cardDeliveryInfoChange.PHONE.isChange = false;
    this.model.setPhoneData(this.detailData);
  }
  // 배송정보 주소 수정
  onClickCardDeliveryInfoAddressChange(){
    this.cardDeliveryInfoChange.ADDRESS.isChange = false;
    this.postCardDeliveryInfoAddressChange();
  }
  // 배송정보 주소 수정 취소
  onClickCardDeliveryInfoAddressChangeCancle(){
    this.cardDeliveryInfoChange.ADDRESS.isChange = false;
    this.model.setAddressData(this.detailData.address);
  }

  // 다음 주소검색 팝업
  onClickAddressSearchPopup(){
    this.isAddressSearchPopup = true;
  }
  onClickCloseAddressSearchPopup(){
    this.isAddressSearchPopup = false;
  }
  onClickCompleteAddressSearchPopup(data){
    this.model.setDaumAddressData(data);
    this.isAddressSearchPopup = false;
  }
  
  // [API] 배송정보 전화번호 수정
  postCardDeliveryInfoPhoneChange(){
    const path = `${apiPath.CS_CARD_PHONE_CHANGE.format(this.detailData.cardNumber)}`;
    const data = this.model.getPhoneData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.detailData.applicantPhoneNumber = data.changedParentPhoneNumber;
      store.dispatch('commonToast/fetchToastStart', '전화번호가 수정되었습니다.');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  
  // [API] 배송정보 주소 수정
  postCardDeliveryInfoAddressChange(){
    const path = `${apiPath.CS_CARD_ADDRESS_CHANGE.format(this.detailData.cardNumber)}`;
    const data = this.model.getAddressData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.detailData.address = data;
      store.dispatch('commonToast/fetchToastStart', '주소가 수정되었습니다.');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // [API] 카드 재배송 확인
  getCardRedeliveryCheck(){
    const path = `${apiPath.CS_CARD_REDELIVERY_CHECK.format(this.detailData.cardNumber)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.deliveryCheckData = resultData.body
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}