
export default class CardReturnModel {
  constructor(){
    this.PHONE = '';
    this.ADDRESS = {
      addressCategory: '', // 집:HOME / 기타:ETC
      addressType: '', // 지번 주소:NUMBER_ADDRESS / 도로명 주소:ROAD_ADDRESS
      address: '',
      buildingName: '',
      dong: '',
      isApartment: true,
      numberAddress: '',
      roadAddress: '',
      sido: '',
      sigugun: '',
      zipCode: '',
      detailAddress: '',
    }
  }
  // 전화번호
  setPhoneData(obj){
    const { applicantPhoneNumber } = obj;
    this.PHONE = applicantPhoneNumber;
  }
  // 주소
  setAddressData(obj){
    const { addressType, address, detailAddress, zipCode, numberAddress, roadAddress, buildingName, isApartment, sido, sigugun, dong, addressCategory} = obj;
    this.ADDRESS.addressCategory = addressCategory;
    this.ADDRESS.addressType = addressType;
    this.ADDRESS.address = address;
    this.ADDRESS.buildingName = buildingName;
    this.ADDRESS.dong = dong;
    this.ADDRESS.isApartment = isApartment;
    this.ADDRESS.numberAddress = numberAddress;
    this.ADDRESS.roadAddress = roadAddress;
    this.ADDRESS.sido = sido;
    this.ADDRESS.sigugun = sigugun;
    this.ADDRESS.zipCode = zipCode;
    this.ADDRESS.detailAddress = detailAddress;
  }
  // 다음주소 검색 결과 -> 주소 적용
  setDaumAddressData(obj){
    const { userSelectedType, address, buildingName, bname, apartment, jibunAddress, roadAddress, sido, sigugun, zonecode, } = obj;
    let addressType = 'NUMBER_ADDRESS';
    if(userSelectedType === 'R') { addressType =  'ROAD_ADDRESS'; }

    this.ADDRESS.addressType = addressType; // 검색된 주소 타입
    this.ADDRESS.address = address;
    this.ADDRESS.buildingName = buildingName;
    this.ADDRESS.dong = bname; // 법정동/법정리 이름
    this.ADDRESS.isApartment = Boolean(apartment === 'Y'); // 공동주택 여부(아파트, 연립주택, 다세대주택 등)
    this.ADDRESS.numberAddress = jibunAddress; // 지번주소
    this.ADDRESS.roadAddress = roadAddress; // 도로주소
    this.ADDRESS.sido = sido;
    this.ADDRESS.sigugun = sigugun;
    this.ADDRESS.zipCode = zonecode;
  }

  getPhoneData(){
    return { changedParentPhoneNumber : this.PHONE.replaceAll('-','') }
  }
  getAddressData(){
    return this.ADDRESS
  }
}